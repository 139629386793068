<script setup>
import PageHeader from "@/components/page-header.vue";
import QuotesList from "@/views/pages/quotes/components/QuotesList.vue";

const items = [
  {
    text: "Rates",
  },
  {
    text: "List",
    active: true,
  },
]
</script>


<template>
  <PageHeader title="Rates" :items="items"/>

  <QuotesList/>
</template>