<script>
import Table from "@/components/tables/table.vue";


export default {
  components: {
    Table
  },
  data() {
    return {
      headers: [
        {
          label: 'Type',
          field: 'quote_type',
          align: 'center',
          excel_data: (row) => row.quote_type ? row.quote_type : '',
          searchType: 'select',
          searchOptions: [
            {value: '', label: 'All'},
            {value: 'container', label: 'Container'},
            {value: 'wagon', label: 'Wagon'},
          ],
        },
        {
          label: 'Departure',
          field: 'departure',
          align: 'center',
          excel_data: (row) => row.departure ? row.departure : ''
        },
        {
          label: 'Destination',
          field: 'destination',
          align: 'center',
          excel_data: (row) => row.destination ? row.destination : ''
        },
        {
          label: 'Product',
          field: 'product',
          align: 'center',
          excel_data: (row) => row.product ? row.product : ''
        },
        {
          label: 'Container type',
          field: 'container_type',
          align: 'center',
          excel_data: (row) => row.container_type ? row.container_type : '',
          searchType: 'select',
          searchOptions: [
            {value: '', label: 'All'},
            {value: '20', label: '20'},
            {value: '20HC', label: '20HC'},
            {value: '40', label: '40'},
            {value: '40HC', label: '40HC'},
            {value: '45', label: '45'},
          ],
        },
        {
          label: 'Quantity',
          field: 'quantity',
          align: 'center',
          excel_data: (row) => row.quantity ? row.quantity : ''
        },
        {
          label: 'Weight',
          field: 'weight',
          align: 'center',
          excel_data: (row) => row.weight ? row.weight : ''
        },
        {
          label: 'First name',
          field: 'first_name',
          align: 'center',
          excel_data: (row) => row.first_name ? row.first_name : ''
        },
        {
          label: 'Last name',
          field: 'last_name',
          align: 'center',
          excel_data: (row) => row.last_name ? row.last_name : ''
        },
        {
          label: 'Email',
          field: 'email',
          align: 'center',
          excel_data: (row) => row.email ? row.email : ''
        },
        {
          label: 'Phone',
          field: 'phone',
          align: 'center',
          excel_data: (row) => row.phone ? row.phone : ''
        },
        {
          label: 'Comment',
          field: 'comment',
          align: 'center',
          excel_data: (row) => row.comment ? row.comment : ''
        },
      ]
    }
  }
}
</script>

<template>
  <Table url="/core/quote/list/" :headers="headers" name="Quote List" :searchable="true"
         :selectable="true"/>
</template>

<style scoped>

</style>